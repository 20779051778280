import '../scss/site.scss';
import '../scss/framework-utils.scss';

import $ from 'jquery';
import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

window.jQuery = $;
window.$ = $;
const sentryDsn = 'https://9c6253d8a66e4a3bbd74fcf4ca922ae0@o37692.ingest.sentry.io/6252267';

function initialise() {
    if (sentryDsn) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(sentryDsn);
            });
    }

    /*  Share widget  ------------------------ */
    const shareEls = document.querySelectorAll('[data-share-url]');
    if (shareEls.length > 0) {
        import(/* webpackChunkName: "share.widget" */ './modules/share.widget.js')
            .then(({ default: share }) => {
                share.init();
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "tables" */ './modules/responsive-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /*  People Bio Collapsibles  ------------------------ */
    const bioCollapsibleEls = document.querySelectorAll('[data-parent=".bio"]');
    if (bioCollapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/bio-collapsible')
            .then(({ default: bioCollapsibleInit }) => {
                bioCollapsibleInit([...bioCollapsibleEls]);
            });
    }

    /*  Header Collapsibles  ------------------------ */
    const headerControlEls = document.querySelectorAll('.header-collapsible');
    if (headerControlEls.length > 0) {
        import(/* webpackChunkName: "header-collapsible" */ './components/header-collapsible.js')
            .then(({ default: headerCollapsibleInit }) => {
                headerCollapsibleInit([...headerControlEls]);
            });
    }

    /*  Sticky Header  ------------------------ */
    const stickyHeaderContainerEls = document.querySelectorAll('.header-container--sticky');
    if (stickyHeaderContainerEls.length > 0) {
        import(/* webpackChunkName: "header" */ './modules/header')
            .then(({ default: init }) => {
                init([...stickyHeaderContainerEls]);
            });
    }

    /*  Card slider - Glide  -------------- */
    const slider = document.querySelectorAll('.glide');
    if (slider.length > 0) {
        import(/* webpackChunkName: "glide" */ './modules/glide')
            .then(({ default: sliderContainerInit }) => {
                sliderContainerInit([...slider]);
            });
    }

    /*  Head room  ------------------------ */
    const header = document.querySelector('header');
    if (header) {
        import(/* webpackChunkName: "head-room" */ './modules/head-room')
            .then(({ default: init }) => {
                init(header);
            });
    }

    /*  Mobile Menu  ------------------------- */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    /*  Authenticator  ------------------------ */
    const authenticator = document.getElementById('rnzcgp-auth-container');
    if (authenticator) {
        import(/* webpackChunkName: "authenticator" */ './authenticator/index.js')
            .then(({ default: authenticatorInit }) => {
                authenticatorInit(authenticator);
            });
    }

    /*  Profile app  ------------------------ */
    const profileAppEl = document.getElementById('rnzcgp-profile-app-container');
    if (profileAppEl) {
        import(/* webpackChunkName: "profileApp" */ './profile/index.js')
            .then(({ default: profileInit }) => {
                profileInit(profileAppEl);
            });
    }

    /*  CPD app  ------------------------ */
    const cpdAppEl = document.getElementById('rnzcgp-cpd-app-container');
    if (cpdAppEl) {
        import(/* webpackChunkName: "cpdApp" */ './cpd/index.js')
            .then(({ default: cpdInit }) => {
                cpdInit(cpdAppEl);
            });
    }

    /*  Dashboard app  ------------------------ */
    const dashboardAppEl = document.getElementById('rnzcgp-dashboard-app-container');
    if (dashboardAppEl) {
        import(/* webpackChunkName: "dashboardApp" */ './dashboard/index.js')
            .then(({ default: dashInit }) => {
                dashInit(dashboardAppEl);
            });
    }

    // /* site wide alert(s) ------------------------ */
    const alertEls = document.querySelectorAll('[data-alert]');
    if (alertEls.length > 0) {
        import(/* webpackChunkName: "alerts" */ './modules/alerts.js')
            .then(({ default: alertsInit }) => {
                alertsInit([...alertEls]);
            });
    }

    // /* Youtube video player ------------------------ */
    const videoContainer = document.querySelectorAll('[data-video-id]');
    if (videoContainer.length > 0) {
        import(/* webpackChunkName: "video-player" */ './modules/video-player.js')
            .then(({ default: videoPlayerInit }) => {
                videoPlayerInit([...videoContainer]);
            });
    }

    /*  Simple filter and search ------------- */
    const formElem = document.querySelectorAll('[data-filter-form]');
    const listingElem = document.querySelectorAll('[data-listing]');

    if (listingElem.length > 0 && formElem.length > 0) {
        import(/* webpackChunkName: "simple-filter" */ './modules/ajax-filter.js')
            .then(({ default: filterInit }) => {
                filterInit(formElem[0], listingElem[0]);
            });
    }

    /*  Simple pagination  ------------- */
    if (listingElem.length > 0) {
        import(/* webpackChunkName: "simple-pagination" */ './modules/pagination.js')
            .then(({ default: paginationInit }) => {
                paginationInit(listingElem[0]);
            });
    }

    /* News load by category ----------- */
    const newsCategoriesElem = document.querySelectorAll('[data-news-categories]');
    if (newsCategoriesElem.length > 0) {
        import(/* webpackChunkName: "news-load-by-category" */ './modules/news-items-by-category.js')
            .then(({ default: init }) => {
                init();
            });
    }

    /* GP Pulse Subscribe ----------- */
    const gpPulseFormEls = document.querySelectorAll('[data-gp-pulse-subscribe]');
    if (gpPulseFormEls.length > 0) {
        import(/* webpackChunkName: "gp-pulse-subscribe" */ './modules/gp-pulse-subscribe.js')
            .then(({ default: init }) => {
                init(gpPulseFormEls);
            });
    }

    /*  Add Job app  ------------------------ */
    const addJobAppEl = document.getElementById('add-job-container');
    if (addJobAppEl) {
        import(/* webpackChunkName: "dashboardApp" */ './website/job-add.js')
            .then(({ default: addJobInit }) => {
                addJobInit(addJobAppEl);
            });
    }

    /*  Event Calendar  -------------------------- */
    const calendarEls = document.querySelectorAll('[data-calendar]');
    if (calendarEls.length > 0) {
        import(/* webpackChunkName: "calendar" */ './modules/calendar.js')
            .then(({ default: calendarInit }) => {
                calendarInit([...calendarEls]);
            });
    }

    /*  Event ics calendar link   -------------------------- */
    const calendarLinkEls = document.querySelectorAll('[data-calendar-link]');
    if (calendarLinkEls.length > 0) {
        import(/* webpackChunkName: "calendar" */ './modules/calendar-link.js')
            .then(({ default: calendarLinkInit }) => {
                calendarLinkInit([...calendarLinkEls]);
            });
    }
}

polyfill().then(() => onPageReady(initialise));
